.Selectable .DayPicker-Day {
  border-radius: 0!important;
}
.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50%!important;
  border-bottom-left-radius: 50%!important;
}
.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50%!important;
  border-bottom-right-radius: 50%!important;
}

.EvntsColor .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #E74C3C!important;
}

.EvntsColor .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0b3b3 !important;
  color: #E74C3C;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) { 
  background-color: #E74C3C!important;

}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #E74C3C!important;
} 