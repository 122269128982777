[type="radio"]:checked+label:before,
[type="checkbox"]:checked+label:before,
[type="radio"]:not(:checked)+label:before,
[type="checkbox"]:not(:checked)+label:before {
    content: "";
    position: absolute;
    right: 10px;
    top: 0px;
    width: 18px;
    height: 18px;
    border: 1px solid #9b9b9b;
    background: #fff;
    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
}

[type="radio"]:checked+label,
[type="checkbox"]:checked+label,
[type="radio"]:not(:checked)+label,
[type="checkbox"]:not(:checked)+label {
    position: relative;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
    width: 100%;
    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before,
[type="radio"]:checked+label:after {
    border-radius: 100%;
}

[type="checkbox"]:checked+label:before,
[type="checkbox"]:not(:checked)+label:before,
[type="checkbox"]:checked+label:after {
    border-radius: 2px;
}
