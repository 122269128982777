* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  box-sizing: border-box;
}

/*
  Menu
*/
nav#slide-menu {
  position: absolute;
  top: 56px;
  display: none;
  -moz-transition: all 300ms;
  -webkit-transition: all 300ms;
  transition: all 300ms;
  font-family: "Pluto", sans-serif;
  z-index: 999;
  margin-left:  281px;
  box-shadow: 2px 2px 8px #dbdbdb;
}

@media(max-width: 768px){
  nav#slide-menu {
    margin-left:  1px;
  }
}


.menu-active {
  display: flex!important;
  opacity: 1!important;
}

nav#slide-menu > ul {
  background-color: #FFF;
  display: block;
  margin: 0px;
  /* padding: 10px; */
  list-style: none;
  -moz-transition: all 300ms;
  -webkit-transition: all 300ms;
  transition: all 300ms;
  width: 0px;
}

.menu-active > ul {
  width: 200px!important;
}

nav#slide-menu > ul li {
  color: #333;
  padding: 16px 0;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
}


nav#slide-menu > ul li span{
  padding-left: 10px;
}

nav#slide-menu > ul li a {
  color: #333;
  text-decoration: none;
}

nav#slide-menu > ul li a:hover {
  color: #c5686b;
}

nav#slide-menu > ul li:hover {
  color: #c5686b;
}

nav#slide-menu > ul li.sep {
  margin-top: 7px;
  padding-top: 14px;
  border-top: 1px solid #6b7b8d;
}

.menu-active nav#slide-menu {
  left: 0px;
}

.menu-active nav#slide-menu ul {
  left: 0px;
  opacity: 1;
}

.close_menu {
  cursor: pointer;
  text-align: right;
  display: block;
  font-size: 24px;
  color: gray;
  margin-top: -20px;
  margin-right: -15px;
}
/*
  Content
*/

.menu-servicos {
  background: transparent;
  cursor: pointer;
  border-radius: 5px;
  -moz-transition: all 300ms;
  -webkit-transition: all 300ms;
  transition: all 300ms;
}


.menu-servicos:hover:before {
  color: #fcfeff;
}

body div#content {
  left: 284px;
  border-radius: 7px 0 0 7px;
}

body div#content .menu-servicos {
  left: 294px;
}

.servicos__menu--item {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #fff;
  border-radius: 3px;
  z-index: 1;
  -webkit-transform-origin: 4px 0px;
  transform-origin: 4px 0px;
  transition: background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease, -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease, -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}
